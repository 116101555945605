<!-- Using autocomplete="new-password" to avoid autocomplete -->
<mat-form-field appearance="outline">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    [type]="type"
    [formControl]="autocompleteControl"
    [required]="required"
    [matAutocomplete]="auto"
    [name]="id ? id : null"
    [id]="id ? id : null"
    [attr.data-cy]="dataCy"
    [placeholder]="placeholder"
    [ltlccRemoveCharacters]="removeCharactersRegex"
    (focus)="handleInputFocus()"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="optionSelected($event)"
    (closed)="handleClosed()"
  >
    <ng-container *ngIf="filteredOptions$ | async as filteredOptions">
      <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
        <ng-container *ngIf="option.renderer">
          <ng-container *ngComponentOutlet="option.renderer"></ng-container>
        </ng-container>
        <ng-container *ngIf="!option.renderer">
          {{ option.label }}
        </ng-container>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <mat-error><ltlcc-form-error-message></ltlcc-form-error-message></mat-error>
  <ng-container *ngIf="!(filteredOptions$ | async)">
    <mat-spinner diameter="20" matSuffix></mat-spinner>
  </ng-container>
  <button
    *ngIf="(filteredOptions$ | async)?.length && autocompleteControl.enabled"
    matSuffix
    mat-icon-button
    type="button"
    (click)="handleExpandMoreClick()"
  >
    <mat-icon>expand_more</mat-icon>
  </button>
  <mat-hint>
    {{ hint }}
    <ng-content select="mat-hint"></ng-content>
  </mat-hint>
</mat-form-field>
