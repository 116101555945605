export class PDFHelper {
  static base64ToBlob(base64: string, mime = 'application/pdf'): Blob {
    mime = mime || '';

    const sliceSize = 1024;
    const byteChars = window.atob(base64);
    const byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
      const slice = byteChars.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mime });
  }

  static URL = window.URL || window.webkitURL;

  static createObjectURL(data: string, mime?: string): string {
    const blob = PDFHelper.base64ToBlob(data, mime);
    const fl = PDFHelper.URL.createObjectURL(blob);
    return fl;
  }

  static openPDF(data: string, mime?: string): Window {
    return window.open(this.createObjectURL(data, mime));
  }

  static printPDF(data: string, mime?: string): void {
    PDFHelper.openPDF(data, mime).print();
  }

  static generateDownloadFileBase64(data: string, fileName: string, mime?: string): void {
    const blob = PDFHelper.base64ToBlob(data, mime);

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    setTimeout(() => URL.revokeObjectURL(a.href), 7000);
  }

  static downloadFile(data: Blob, fileName: string): void {
    const blob = data;
    const downloadLink = document.createElement('a');
    const downloadUrl = URL.createObjectURL(blob);
    const isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
    if (isSafariBrowser) {
      // if Safari open in new window to save file with random filename.
      downloadLink.setAttribute('target', '_blank');
    }
    if ((<any>navigator).msSaveOrOpenBlob) {
      (<any>navigator).msSaveOrOpenBlob(blob, fileName);
    } else {
      downloadLink.setAttribute('href', downloadUrl);
      downloadLink.setAttribute('download', fileName);
      downloadLink.style.visibility = 'hidden';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  static isBase64String(s: string): boolean {
    const base64Regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64Regex.test(s);
  }
}
