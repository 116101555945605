import { Inject, Injectable } from '@angular/core';
import {
  AccessRoleName,
  BillOfLadingApiService,
  CountryCode,
  ExternalUserData,
  GeneralInfoWebAccountInfo,
  LocationUser,
  MembershipService,
  UserManagementApiService,
  UserProfileResponse,
} from '@ltlc/api';
import { XpoLtlAuthConfig, XpoLtlAuthenticationService } from '@ltlc/auth';
import { TranslateHelper } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { XpoAuthenticationService, XpoAuthUser, XPO_AUTH_CONFIG } from '@xpo/ngx-auth';
import { User } from 'oidc-client';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, retry, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { WebUser } from './web-user.interface';

@Injectable({ providedIn: 'root' })
export class LtlConnectUserService {
  private _getPartnerByUser$ = new BehaviorSubject<LocationUser[] | null>(null);
  readonly getPartnerByUser$ = this._getPartnerByUser$.pipe(
    switchMap((locationUsers) => {
      if (!locationUsers) {
        return this.userManagementApiService.getPartnerByUser().pipe(
          take(1),
          tap((locationUsers) => this._getPartnerByUser$.next(locationUsers))
        );
      }
      return of(locationUsers);
    }),
    shareReplay()
  );

  readonly isAdminUser$ = this.getPartnerByUser$.pipe(
    catchError((err) => {
      console.error(err);
      return of([]);
    }),
    map((locationUsers: LocationUser[]) => {
      const location = locationUsers.reduce((p, c) => {
        p.push(c.roleName.toLowerCase());
        c.children?.forEach((v) => p.push(v.roleName.toLowerCase()));
        return p;
      }, []);
      return location.includes(AccessRoleName.admin.toLowerCase());
    }),
    shareReplay()
  );

  // preferred observable to get user info
  private _webUser$ = new BehaviorSubject<{ cached?: WebUser; override?: Partial<WebUser> } | null>(null);
  readonly webUser$: Observable<WebUser | null> = combineLatest([
    this.authenticationService.getUser$(),
    this._webUser$,
  ]).pipe(
    switchMap(([xpoUser, cachedUser]) => {
      if (!xpoUser) {
        return of(null);
      }
      if (cachedUser?.cached && cachedUser.cached.userName?.toLowerCase() === xpoUser.profile.upn?.toLowerCase()) {
        return of({ ...cachedUser.cached, ...cachedUser?.override });
      }
      const partnerMasterUser$: Observable<UserProfileResponse> | Observable<null> = !!xpoUser.profile.upn
        ? this.userManagementApiService.getUserProfile(xpoUser.profile.upn).pipe(catchError(() => of(null)))
        : of(null);

      const memberUser$: Observable<ExternalUserData> | Observable<null> = this.isPartnerManagerUser
        ? of(null)
        : this.getMembershipUserData().pipe(
            retry(1),
            catchError(() => of(null))
          );

      const generalInfo$: Observable<GeneralInfoWebAccountInfo | null> = this.isPartnerManagerUser
        ? of(null)
        : this.membershipService.getGeneralInformation().pipe(
            retry(1),
            catchError(() => of(null))
          );

      return combineLatest([partnerMasterUser$, memberUser$, generalInfo$]).pipe(
        take(1),
        map(([partnerMasterUser, memberUser, generalInfo]) => {
          const webUser: WebUser = <WebUser>{
            scopes: xpoUser.scopes,
          };

          if (partnerMasterUser) {
            this.mapPartnerMasterData(partnerMasterUser, webUser);
          }
          if (memberUser) {
            webUser.userId = memberUser.userId;
            webUser.profileInstId = memberUser.profileInstId;
            webUser.roles = memberUser.roles;
            webUser.profile = {
              firstName: memberUser.givenName,
              lastName: memberUser.lastName,
              email: memberUser.emailAddress,
              phoneNumber: memberUser.telephone,
              addressLine1: memberUser.streetAddress,
              stateCode: memberUser.stateCd,
              postalCode: memberUser.postalCode,
            };

            this.mapGeneralInfoData(generalInfo ?? <GeneralInfoWebAccountInfo>{}, webUser);
          }

          this.mapXpoUser(xpoUser, webUser);

          return { ...webUser, ...cachedUser?.override };
        }),
        tap((webUser) => this._webUser$.next({ cached: webUser }))
      );
    }),
    shareReplay()
  );

  // This web user version is used in General Info page. This doesn't call the membership user api
  readonly webUserLight$: Observable<WebUser | null> = this.authenticationService.getUser$().pipe(
    switchMap((xpoUser) => {
      if (!xpoUser) {
        return of(null);
      }
      const partnerMasterUser$: Observable<UserProfileResponse> | Observable<null> = !!xpoUser.profile.upn
        ? this.userManagementApiService.getUserProfile(xpoUser.profile.upn).pipe(catchError(() => of(null)))
        : of(null);

      const generalInfo$: Observable<GeneralInfoWebAccountInfo> | Observable<null> = this.isPartnerManagerUser
        ? of(null)
        : this.membershipService.getGeneralInformation().pipe(
            retry(1),
            catchError(() => of(null))
          );

      return combineLatest([partnerMasterUser$, generalInfo$]).pipe(
        take(1),
        map(([partnerMasterUser, generalInfo]) => {
          const webUser: WebUser = <WebUser>{
            scopes: xpoUser.scopes,
          };

          if (partnerMasterUser) {
            this.mapPartnerMasterData(partnerMasterUser, webUser);
          }
          if (generalInfo) {
            this.mapGeneralInfoData(generalInfo, webUser);
          }
          this.mapXpoUser(xpoUser, webUser);

          return webUser;
        })
      );
    })
  );

  constructor(
    @Inject(XPO_AUTH_CONFIG) private authConfig: XpoLtlAuthConfig,
    private membershipService: MembershipService,
    private billOfLadingApiService: BillOfLadingApiService,
    private ltlAuthenticationService: XpoLtlAuthenticationService,
    private authenticationService: XpoAuthenticationService,
    private userManagementApiService: UserManagementApiService,
    private translate: TranslateService
  ) {}

  get isPartnerManagerUser(): boolean {
    return true;
  }

  refreshUser(override?: Partial<WebUser>): void {
    this._webUser$.next({
      cached: null,
      override,
    });
  }

  getXpoAuthUserData$(): Observable<XpoAuthUser> {
    return this.authenticationService.getUser$();
  }

  /**
    Need this for the cases where we log in with partner manager, but the apis need the legacy profile instance id to
    work. separating this out into its own function to be used everywhere we need it. that way we can avoid calling
    the legacy api for as long as possible. there is a delay with when teh user first registers with us and it syncing
    to the legacy system so this api may fail if the user creates a profile and quickly logs in
  */
  getProfileInstId$(): Observable<string | null> {
    return this.webUser$.pipe(map((v) => v.profileInstId ?? null));
  }

  isLoggedIn(): boolean {
    return !!this.authenticationService.getUser();
  }

  isTestUser(): boolean {
    return [
      'mdonato@xpo.com',
      'saravanan.thillaivanarajan@xpo.com',
      'scott.dibenedetto@xpo.com',
      'charan.seemakurthy@xpo.com',
      'joanne.rabanal@xpo.com',
    ].includes(this.authenticationService.getUser()?.profile?.email);
  }

  isBetaUser(): boolean {
    return (
      [
        'Raymond.ramos@bedrosians.com',
        'mark.deligero@xpo.com',
        'deligero.mark@con-way.com',
        'Ryan@BuyRiteBeauty.com',
        'randy@uquality.com ',
      ].some((email) => email.toLowerCase() === this.authenticationService.getUser()?.profile?.email?.toLowerCase()) ||
      this.isTestUser()
    );
  }

  signOut(): void {
    this.ltlAuthenticationService.logout();
    this.authenticationService.signOut$().pipe(take(1)).subscribe();
  }

  private getMembershipUserData(): Observable<ExternalUserData> {
    return this.billOfLadingApiService.getExternalUserData().pipe(retry(2));
  }

  private mapPartnerMasterData(partnerMasterUser: UserProfileResponse, webUser: WebUser) {
    webUser.userName = partnerMasterUser.userName?.toLowerCase();
    webUser.profileInstId = partnerMasterUser.profileInstanceId;
    webUser.profile = {
      firstName: partnerMasterUser.firstName,
      lastName: partnerMasterUser.lastName,
      email: partnerMasterUser.email,
      phoneNumber: partnerMasterUser.phoneNumber,
      phoneAreaCode: partnerMasterUser.phoneAreaCode,
      phoneCountryCode: partnerMasterUser.phoneCountryCode,
      addressLine1: partnerMasterUser.address1,
      addressLine2: partnerMasterUser.address2,
      cityName: partnerMasterUser.city,
      companyName: partnerMasterUser.companyName,
      countryCode: partnerMasterUser.countryCode,
      stateCode: partnerMasterUser.stateCode,
      postalCode: partnerMasterUser.postalCode,
      preferredLanguageCode: TranslateHelper.checkLanguageSupported(
        TranslateHelper.standardizeLanguageCode(partnerMasterUser.preferredLanguageCode)
      ),
    };
    webUser.permissionId = partnerMasterUser.betaTester?.permissionId;
  }

  private mapGeneralInfoData(generalInfo: GeneralInfoWebAccountInfo, webUser: WebUser) {
    webUser.userId = generalInfo.userLogonId || webUser.userId;
    webUser.profile = {
      ...webUser.profile,
      firstName: generalInfo.firstName || webUser.profile.firstName,
      lastName: generalInfo.lastName || webUser.profile.lastName,
      email: generalInfo.emailAddress?.emailAddr || webUser.profile.email,
      companyName: generalInfo.companyName || webUser.profile.companyName,
    };

    if (generalInfo.telephone) {
      webUser.profile.phoneNumber = generalInfo.telephone.phoneNbr || webUser.profile.phoneNumber;
      webUser.profile.phoneCountryCode =
        generalInfo.telephone.countryCd?.toString() || webUser.profile.phoneCountryCode;
      webUser.profile.phoneAreaCode = generalInfo.telephone.extension?.toString() || webUser.profile.phoneAreaCode;
    }

    if (generalInfo.address) {
      webUser.profile.addressLine1 = generalInfo.address.addressLine1 || webUser.profile.addressLine1;
      webUser.profile.addressLine2 = generalInfo.address.addressLine2 || webUser.profile.addressLine2;
      webUser.profile.cityName = generalInfo.address.cityName || webUser.profile.cityName;
      webUser.profile.stateCode = generalInfo.address.stateCd || webUser.profile.stateCode;
      webUser.profile.countryCode = <CountryCode>generalInfo.address.countryCd || webUser.profile.countryCode;
      webUser.profile.postalCode = generalInfo.address.postalCd || webUser.profile.postalCode;
    }
    webUser.profile.workTitle = generalInfo.title || webUser.profile.workTitle;
  }

  private mapXpoUser(xpoUser: User, webUser: WebUser) {
    webUser.userName = webUser.userName || xpoUser.profile.upn;
    webUser.profile = {
      ...webUser.profile,
    };
    webUser.profile.firstName = webUser.profile?.firstName || xpoUser.profile.given_name;
    webUser.profile.lastName = webUser.profile?.lastName || xpoUser.profile.family_name;
    webUser.profile.email = webUser.profile?.email || xpoUser.profile.email;
    webUser.profile.preferredLanguageCode =
      webUser.profile.preferredLanguageCode || TranslateHelper.getClientPreferredLanguage(this.translate);
  }
}
