import { Injectable } from '@angular/core';
import { WebConfigService } from '@ltlc/core';
import { combineLatest, Observable, of } from 'rxjs';
import { AnalyticsData } from './consts';
import { XpoGtagAnalyticsService } from './gtag-analytics.service';
import { XpoGoogleTagManagerAnalyticsService } from './gtm-analytics.service';
import { XpoTrackingAnalyticsService } from './tracking-analytics-base.service';

@Injectable({ providedIn: 'root' })
export class XpoLTLTrackingService {
  private trackers: XpoTrackingAnalyticsService[] = [];

  constructor(
    gtagAnalyticsService: XpoGtagAnalyticsService, // pageview (v1) this only changes the dp param
    gtmAnalyticsService: XpoGoogleTagManagerAnalyticsService, // pageview (v1) don't change url, page_view (v2) change url with dl and dr
    private webConfigService: WebConfigService
  ) {
    this.trackers.push(gtagAnalyticsService, gtmAnalyticsService);
  }

  initializeTracking(): Observable<boolean[]> {
    return this.webConfigService.isProd ? combineLatest(this.trackers.map((t) => t.initialize())) : of([]);
  }

  // See reference: https://developers.google.com/analytics/devguides/collection/protocol/v1/parameters
  trackEvent(eventName: string, username: string, eventValue?: Object): void {
    this.trackers.filter((t) => t.initialized).forEach((t) => t.trackEvent(eventName, username, eventValue));
  }

  trackPageView(pagePath: string, user: AnalyticsData, siteCode: string, area?: string): void {
    this.trackers.filter((t) => t.initialized).forEach((t) => t.trackPageView(pagePath, user, siteCode, area));
  }
}
