import * as deepmerge from 'deepmerge';

export class DeepMergeHelper {
  // Custom isMergeableObject function
  static isMergeableObject = (val: any): boolean => {
    return typeof val === 'object' && val !== null && !Array.isArray(val);
  };
  // Custom merge function to prioritize non-null values from destination obj
  static customMerge = (key: string, options?: deepmerge.Options): any => {
    return (destination: any, source: any): any => {
      // If the source value is null or undefined, use the destination value
      if (source == null || Object.values(source).every((v) => !v)) {
        return destination;
      }
      // Use the source value if it's not mergeable
      if (!DeepMergeHelper.isMergeableObject(source)) {
        return source;
      }
      return deepmerge(destination, source, options);
    };
  };
}
