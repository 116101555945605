import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URI } from '../tokens';
import { RefundRequest, RefundResponse } from './interfaces/web-dispute.interface';

@Injectable({ providedIn: 'root' })
export class WebDisputeApiService {
  constructor(@Inject(API_URI) private baseApiUri: string, private httpClient: HttpClient) {}

  get webDisputeUri(): string {
    return `${this.baseApiUri}/webdispute/1.0/dispute/`;
  }

  submitMabdRefundForm(request: RefundRequest): Observable<RefundResponse> {
    const url = `${this.webDisputeUri}SubmitMABDRefund`;
    return this.httpClient.post<any>(url, request);
  }

  submitRefundForm(request: RefundRequest): Observable<RefundResponse> {
    const url = `${this.webDisputeUri}SubmitRefund`;
    return this.httpClient.post<any>(url, request);
  }
}
