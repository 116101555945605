import { coerceNumberProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Optional,
  Output,
  ViewEncapsulation,
  EventEmitter,
} from '@angular/core';
import { ControlContainer, FormArray, FormArrayName, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { isValidProNumberValidator, ProNumberHelper } from '../../helpers';

@UntilDestroy()
@Component({
  selector: 'ltlcc-pro-chip-list',
  templateUrl: './pro-chip-list.component.html',
  styleUrls: ['./pro-chip-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [{ provide: ControlContainer, useExisting: FormArrayName }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'ltlcc-ProChipList' },
})
export class ProChipListComponent {
  @Output() proList = new EventEmitter<FormArray>(null);
  @Input() label: string = this.translate.instant('form.labels.proNumber');
  @Input() hint: string;
  @Input()
  get maxAllowed(): number {
    return this._maxAllowed;
  }
  set maxAllowed(v: number) {
    this._maxAllowed = coerceNumberProperty(v);
  }
  private _maxAllowed!: number;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    @Optional() private formArrayDirective: FormArrayName,
    private fb: FormBuilder,
    private translate: TranslateService
  ) {}

  get listArray(): FormArray {
    return this.formArrayDirective?.control as FormArray;
  }

  get required() {
    return this.listArray.hasValidator(Validators.required);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      value.split(',').forEach((p) => {
        if (this.listArray.length < this.maxAllowed) {
          const ctrl = this.fb.control(p, isValidProNumberValidator);
          if (ctrl.valid) {
            ctrl.setValue(ProNumberHelper.formatProNumber(p));
          }
          this.listArray.push(ctrl);
        }
      });
    }
    this.proList.emit(this.listArray);
    if (input) {
      input.value = '';
    }
  }

  remove(pro: FormControl): void {
    const index = this.listArray.controls.findIndex((c) => c.value === pro.value);

    if (index >= 0) {
      this.listArray.removeAt(index);
      this.proList.emit(this.listArray);
    }
  }

  clear(): void {
    this.listArray.clear();
    this.proList.emit(this.listArray);
  }
}
