import { CountryCode, LegacyCountryCode } from '@ltlc/api';

export class CountryCodeHelper {
  static handleBadCanadaCode(countryCode: CountryCode | string, toAPI: boolean): string | CountryCode {
    const countryCodetrimmed = countryCode?.trim() || '';
    if (toAPI) {
      return countryCodetrimmed === CountryCode.CANADA ? LegacyCountryCode.LEGACY_CANADA : countryCodetrimmed;
    } else {
      return countryCodetrimmed === LegacyCountryCode.LEGACY_CANADA ? CountryCode.CANADA : countryCodetrimmed;
    }
  }
}
