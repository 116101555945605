import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { AlertData } from './interfaces/alerts.interface';

@Component({
  selector: 'ltlcc-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ltlcc-Alert',
    '[class.ltlcc-Alert--success]': 'data.status === "success"',
    '[class.ltlcc-Alert--error]': 'data.status === "error"',
    '[class.ltlcc-Alert--warn]': 'data.status === "warn"',
    '[class.ltlcc-Alert--info]': 'data.status === "info"',
  },
})
export class AlertsComponent implements OnInit {
  alertIcon = 'info';

  @Input() data: AlertData;

  constructor(
    @Optional() public snackBarRef?: MatSnackBarRef<AlertsComponent>,
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public snackBarData?: AlertData
  ) {
    if (this.snackBarData) {
      this.data = this.snackBarData;
    }
  }

  ngOnInit(): void {
    this.alertIcon = this.getAlertIcon();
  }

  private getAlertIcon(): string {
    let icon: string;

    switch (this.data.status) {
      case 'success':
        icon = 'check_circle';
        break;
      case 'warn':
        icon = 'info';
        break;
      case 'error':
        icon = 'error';
        break;
      case 'info':
        icon = 'info';
        break;
      default:
        icon = 'info';
        break;
    }
    return icon;
  }

  get title(): string {
    return this.data.message;
  }

  get description(): string {
    return this.data.detailedMessage;
  }

  close() {
    this.snackBarRef?.dismiss();
  }
}
