import { APP_INITIALIZER, NgModule } from '@angular/core';
import { API_URI, CONNECT_API_URI, DOTNET_API_URI } from '@ltlc/api';
import { environment } from '../../environments/environment';
import { WebConfigService } from '@ltlc/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

const initApiStore = (config: WebConfigService) => config.getSetting('apiUri');
const initDotNetApiStore = (config: WebConfigService) => config.getSetting('dotNetApi');
const initConnectApiStore = (config: WebConfigService) => config.getSetting('connectApiUri');

// Also tried with fileReplacements in workspace file but it doesn't work for assets in Angular +9.
const configFileUrl = environment['envName'] === 'S' ? './../../assets/config.uat.json' : './../../assets/config.json';
@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (configManagerService: ConfigManagerService) => () => configManagerService.init(configFileUrl),
      multi: true,
      deps: [ConfigManagerService],
    },
    {
      provide: API_URI,
      useFactory: initApiStore,
      deps: [WebConfigService],
      multi: true,
    },
    {
      provide: DOTNET_API_URI,
      useFactory: initDotNetApiStore,
      deps: [WebConfigService],
      multi: true,
    },
    {
      provide: CONNECT_API_URI,
      useFactory: initConnectApiStore,
      deps: [WebConfigService],
      multi: true,
    },
  ],
})
export class InitConfigModule {}
