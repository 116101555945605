import { animate, state, style, transition, trigger } from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  FormGroupName,
  ValidationErrors,
} from '@angular/forms';
import {
  EXPANSION_PANEL_ANIMATION_TIMING,
  MatExpansionPanel,
  MatExpansionPanelState,
} from '@angular/material/expansion';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { AddressBook, AddressBookApiResponse } from '@ltlc/api';
import { AlertService, ConfirmationDialogComponent, ConfirmationDialogData, ErrorHandlerService } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, isObservable, merge, Observable, Subject, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { AddressBookHelper } from '../../helpers/address-book-helper';
import { AccountForm } from '../account-form';
import { AccountListTreeAutocompleteWrapperComponent } from '../account-list-light/account-list-tree-autocomplete-wrapper/account-list-tree-autocomplete-wrapper.component';
import { TreeListOption } from '../account-list-light/enums/account-list-light.enum';
import { AccountListHelper } from '../account-list-light/helpers/account-list-light.helper';
import {
  AccountFormConfig,
  AccountListLightTreeNode,
  FlatNode,
  TreeListConfig,
} from '../account-list-light/interfaces/account-list-light.interface';
import { AccountDataSourcesService } from '../account-list-light/services/account-data-sources.service';

// TODO: EDit not working with on change errors
@UntilDestroy()
@Component({
  selector: 'ltlc-account-light-picker',
  templateUrl: './account-light-picker.component.html',
  styleUrls: ['./account-light-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  animations: [
    // See matExpansionAnimations from '@angular/material/expansion';
    trigger('bodyExpansion', [
      state('collapsed', style({ backgroundColor: '#ffffff' })), //$xpo-white
      state('expanded', style({ backgroundColor: '#f6f6f6' })), // $xpo-grey--80
      transition('* <=> *', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
    ]),
  ],
})
export class AccountLightPickerComponent implements OnInit, AfterViewInit {
  hint$: Observable<string> | undefined;
  searchControl = new FormControl();
  isLoading = false;
  hintStatus: 'showHide' | 'hide' = 'showHide';
  configList: TreeListConfig = {
    enableDynamicHeight: true,
    enableClearButton: true,
  };

  private _pickRequired$ = new BehaviorSubject<boolean>(false);
  pickRequired$ = this._pickRequired$.asObservable().pipe(distinctUntilChanged());

  private readonly _hasExpanded$ = new Subject<boolean>();

  /**
   * TODO !
   * We should not need this Input and Output given that we have the public expandPanel and closePanel methods
   * I tried to change it but the .open and .close methods on the expansion panel did not work as expected.
   * Maybe it's a Material bug so let's try again when we upgrade to Angular 12
   */
  private _expanded$ = new BehaviorSubject(false);
  readonly expanded$ = this._expanded$.asObservable().pipe(
    distinctUntilChanged(),
    tap((expanded) => this.expandState.emit(expanded)),
    shareReplay(1)
  );
  readonly expansionState$: Observable<MatExpansionPanelState> = this.expanded$.pipe(
    map((expanded) => (expanded ? 'expanded' : 'collapsed'))
  );

  private isAddresBook = false;
  private listIdChanges$: Observable<string>;

  @Input() fieldConfig: AccountFormConfig;
  @Input() label: string;
  @Input() title: string;
  @Input() nodes: AccountListLightTreeNode[] | null = null;
  @Input() dataCy?: string;
  @Input()
  set expanded(expanded: BooleanInput) {
    expanded = coerceBooleanProperty(expanded);
    this._expanded$.next(expanded);
  }
  @Input()
  get expandOnSelect(): boolean {
    return this._expandOnSelect;
  }
  set expandOnSelect(v: BooleanInput) {
    this._expandOnSelect = coerceBooleanProperty(v);
  }
  private _expandOnSelect = false;

  @Output() expandState = new EventEmitter<boolean>();
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  @ViewChild(AccountListTreeAutocompleteWrapperComponent)
  accountPicker: AccountListTreeAutocompleteWrapperComponent;

  constructor(
    private accountDataSourcesService: AccountDataSourcesService,
    private errorService: ErrorHandlerService,
    private alertService: AlertService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    private dialog: MatLegacyDialog,
    private formBuilder: FormBuilder,
    @Optional() private formGroupName: FormGroupName
  ) {}

  ngOnInit(): void {
    if (!this.accountDetailsForm.contains('listId')) {
      this.accountDetailsForm.addControl('listId', new FormControl(null));
    }
    this.listIdChanges$ = this.accountDetailsForm
      .get('listId')
      .valueChanges.pipe(startWith(this.accountDetailsForm.get('listId').value), distinctUntilChanged());

    this.searchControl.setValue(
      this.accountDetailsFormValue?.aliasName ?? this.accountDetailsFormValue?.companyName ?? null
    );

    this.listIdChanges$.pipe(untilDestroyed(this)).subscribe((listId) => {
      if (
        listId &&
        this.accountDetailsFormValue?.companyName &&
        this.searchControl.value !== this.accountDetailsFormValue.companyName
      ) {
        this.searchControl.setValue(this.accountDetailsFormValue.companyName, {
          emitEvent: false,
        });
      }

      if (!this.searchControl.value && listId === TreeListOption.newAddress && this.accountDetailsFormValue?.listId) {
        this.searchControl.setValue(this.translate.instant('accountListLight.newAddress'), {
          emitEvent: false,
        });
      }
      if (!!this.accountDetailsFormValue?.listId && this.invalidForm) {
        this.expandPanel();
      }
    });

    this.hint$ = merge(
      this.expanded$,
      this.listIdChanges$,
      this.accountDetailsForm.valueChanges.pipe(
        map(() => this.accountDetailsForm.valid),
        distinctUntilChanged()
      )
    ).pipe(
      startWith(''),
      switchMap((_) => {
        return this.expanded$.pipe(
          take(1),
          map((expanded) => {
            if (expanded) {
              if (this.invalidForm) {
                this.hintStatus = 'hide';
                return '';
              } else {
                this.hintStatus = 'showHide';
                return this.translate.instant('accountList.hideDetails');
              }
            } else {
              if (!this.accountDetailsForm.get('listId').value && !this.accountDetailsForm.get('companyName').value) {
                // Hide hint if no value is selected in the autocomplete picker
                this.hintStatus = 'hide';
                return '';
              } else {
                this.hintStatus = 'showHide';
                return this.translate.instant('accountList.showDetails');
              }
            }
          })
        );
      })
    );

    this.updateValidatorAutocomplete();
  }

  ngAfterViewInit(): void {
    this.accountPicker.clear = () => {
      this.searchControl.reset();
      this.clearFormOptionOnClear();
    };
  }

  get invalidForm(): boolean {
    return !!this.accountDetailsForm?.invalid;
  }

  get isDirty(): boolean {
    return this.accountDetailsForm.dirty;
  }

  get accountDetailsFormValue(): Partial<AccountForm> | null {
    return this.accountDetailsForm?.getRawValue() ?? null;
  }

  get isNewAddress(): boolean {
    return this.accountDetailsFormValue?.listId === TreeListOption.newAddress.toString();
  }

  get accountDetailsForm(): FormGroup | null {
    return this.formGroupName?.control || null;
  }

  get enableAddressSave(): boolean {
    return (
      this.isAddresBook &&
      !this.accountDetailsFormValue?.listId &&
      this.accountDetailsFormValue?.listId !== TreeListOption.newAddress.toString()
    );
  }

  get enableAddressUpdate(): boolean {
    return (
      this.isAddresBook &&
      !!this.accountDetailsFormValue?.listId &&
      this.accountDetailsFormValue.listId !== TreeListOption.newAddress.toString()
    );
  }

  handleClickHeader(event: PointerEvent): void {
    const target: HTMLElement = <any>event?.['target'];
    const clickHint = target?.classList.contains(AccountListHelper.autocompleteAccountHintClass);
    if (clickHint && this.hintStatus === 'showHide') {
      this.togglePanel();
    }
  }

  selectedNode(node: FlatNode): void {
    if (!this.accountDetailsForm) {
      return;
    }

    const disabledForm = this.accountDetailsForm.disabled;
    this.isAddresBook = node.data.type === this.translate.instant('locationType.A');

    if (!this.accountDetailsForm.contains('dataId') && node.data.addressForm?.dataId) {
      this.accountDetailsForm.addControl('dataId', new FormControl(node.data.addressForm.dataId));
    }

    const value: AccountForm = this.accountDetailsForm.getRawValue();
    const resetValue = { ...node.data.addressForm };
    if (value.dockingInterval?.open || value.dockingInterval?.close) {
      resetValue.dockingInterval = value.dockingInterval;
    }
    this.accountDetailsForm.reset(resetValue);

    if (disabledForm) {
      this.accountDetailsForm.disable({ emitEvent: false });
    }

    if (this.expandOnSelect) {
      this.expandPanel();
    }

    this.searchControl.setValue(node.data.name);
  }

  saveAsNewAddress(): void {
    if (this.isLoading || !this.isDirty || !this.isNewAddress || !this.accountDetailsForm || !this.enableAddressSave) {
      return;
    }

    this.isLoading = true;
    this.accountDataSourcesService
      .saveAsNewAddress(AddressBookHelper.parseAddressForm(this.accountDetailsFormValue))
      .pipe(
        catchError((error) => {
          this.isLoading = false;
          return throwError(error);
        }),
        this.errorService.snackbarOnError(),
        take(1)
      )
      .subscribe((savedAddress: AddressBook) => {
        this.isLoading = false;
        if (savedAddress.companyName) {
          if (!this.accountDetailsForm.contains('companyName')) {
            this.accountDetailsForm.addControl('companyName', new FormControl());
          }
          this.accountDetailsForm.get('companyName').setValue(savedAddress.companyName);
        }

        this.alertService.showApiSuccess({ body: this.translate.instant('accountListLight.addressSaved') });
        this.cd.markForCheck();
      });
  }

  updateAddress(): void {
    if (
      this.isLoading ||
      !this.isDirty ||
      !this.enableAddressUpdate ||
      !this.accountDetailsForm ||
      !this.accountDetailsFormValue.dataId
    ) {
      return;
    }

    const sequenceNbr = Number(this.accountDetailsFormValue.dataId);
    this.isLoading = true;
    this.accountDataSourcesService
      .updateAddress(AddressBookHelper.parseAddressForm(this.accountDetailsFormValue), sequenceNbr)
      .pipe(
        catchError((error) => {
          this.isLoading = false;
          this.cd.markForCheck();
          return throwError(error);
        }),
        this.errorService.snackbarOnError(),
        take(1)
      )
      .subscribe((savedAddress: AddressBookApiResponse) => {
        this.isLoading = false;
        if (savedAddress.data.addressBookEntry.companyName) {
          if (!this.accountDetailsForm.contains('companyName')) {
            this.accountDetailsForm.addControl('companyName', new FormControl());
          }
          this.accountDetailsForm.get('companyName').setValue(savedAddress.data.addressBookEntry.companyName);
        }

        this.alertService.showApiSuccess({ body: this.translate.instant('accountListLight.addressUpdated') });
      });
  }

  panelExpand(panelExpaned: boolean): void {
    this._hasExpanded$.next(panelExpaned);
  }

  expandPanel(): Observable<boolean> {
    this._expanded$.next(true);
    return this._hasExpanded$.asObservable().pipe(filter((expanded) => expanded === true));
  }

  collapsePanel(): Observable<boolean> {
    this._expanded$.next(false);
    return this._hasExpanded$.asObservable().pipe(filter((expanded) => expanded === false));
  }

  togglePanel(): Observable<boolean> {
    this._expanded$.next(!this._expanded$.getValue());
    return this._hasExpanded$.asObservable();
  }

  private clearFormOptionOnClear(): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: <ConfirmationDialogData>{
          body: this.translate.instant('accountList.clearAddressForm'),
          actionColor: 'primary',
          actionText: this.translate.instant('defaults.clear'),
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((answer) => {
        if (answer) {
          this.accountDetailsForm?.reset();
          this.accountDetailsForm?.markAllAsTouched();
          this.collapsePanel();
        }
      });
  }

  private updateValidatorAutocomplete(): void {
    this.accountDetailsForm?.statusChanges.pipe(untilDestroyed(this), startWith('')).subscribe(() => {
      const fakeAccountForm = this.formBuilder.group({});
      const requiredArray: Observable<boolean>[] = [];
      this._pickRequired$.next(false);

      for (const [key, formControl] of Object.entries(this.accountDetailsForm.controls)) {
        fakeAccountForm.addControl(key, new FormControl());
        const fakeControl = <FormControl>fakeAccountForm.get(key);
        if (formControl.validator && formControl.validator(fakeControl)) {
          const errors = formControl.validator(fakeControl);
          if (!!errors?.required) {
            this._pickRequired$.next(true);
            return;
          }
        }
        if (formControl.asyncValidator && isObservable(formControl.asyncValidator(fakeControl))) {
          const asyncRequired$ = (<Observable<ValidationErrors>>formControl.asyncValidator(fakeControl)).pipe(
            take(1),
            map((errors) => !!errors?.required)
          );

          requiredArray.push(asyncRequired$);
        }
      }

      if (!this._pickRequired$.getValue()) {
        combineLatest(requiredArray)
          .pipe(take(1))
          .subscribe((requiredFields) => {
            if (requiredFields.some((required) => required)) {
              this._pickRequired$.next(true);
            }
          });
      }
    });
  }
}
