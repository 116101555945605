import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UserManagementApiService } from '@ltlc/api';
import { ErrorHandlerService, RemoveCharactersDirective } from '@ltlc/core';
import { LoaderService } from '@ltlc/ltl-core';
import { take, timeout } from 'rxjs/operators';
import { SignInParams } from '../app-core/constants/query-params.enum';
import { APP_ROUTE_PATHS } from '../app-core/constants/routes.const';
import { SessionStorageKeys } from '../app-core/constants/session-storage-keys.const';

export const loginHint = 'login_hint';

@Component({
  selector: 'ltlc-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlc-SignIn' },
})
export class SignInComponent implements OnInit {
  APP_ROUTE_PATHS = APP_ROUTE_PATHS;
  signInForm = this.formBuilder.group({
    username: ['', Validators.required],
  });

  cleanEmailInputRegex = RemoveCharactersDirective.RemoveCharactersRegex.cleanEmailInputRegex;

  constructor(
    private userManagementApiService: UserManagementApiService,
    private formBuilder: FormBuilder,
    private errorHandlerService: ErrorHandlerService,
    public loaderService: LoaderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const username = this.route.snapshot.queryParams[SignInParams.userName];

    if (username) {
      this.signInForm.get('username').setValue(username);
    }
  }

  next(): void {
    if (this.signInForm.invalid) {
      return;
    }

    const userNameControl = this.signInForm.get('username') as FormControl;
    const userName = userNameControl.value.trim();

    this.loaderService
      .loadData(this.userManagementApiService.getWebClient(userName))
      .pipe(take(1), timeout(6000), this.errorHandlerService.snackbarOnError('ltl-ext-web'))
      .subscribe((clientId) => {
        navigateToOdicSignInPage(clientId, userName);
      });
  }
}

export const navigateToOdicSignInPage = (clientId: string, userName: string) => {
  sessionStorage.setItem(SessionStorageKeys.clientId, clientId);
  sessionStorage.setItem(loginHint, userName);
  const loginLink = new URL(window.location.origin + APP_ROUTE_PATHS.app.root);
  loginLink.searchParams.append(loginHint, userName);
  window.location.href = loginLink.toString();
};
