import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, NgControl, ValidatorFn, Validators } from '@angular/forms';
import { CountryCode, ErrorDataMoreInfoResponse, ServiceCenter, ShippingtoolsService } from '@ltlc/api';
import { FormControlsBaseComponent, FormGroupTemplateService, Masks } from '@ltlc/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'ltlcc-postal-code',
  templateUrl: './postal-code.component.html',
  styleUrls: ['./postal-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ltlcc-PostalCode' },
})
export class PostalCodeComponent extends FormControlsBaseComponent implements OnInit {
  private readonly minZipCodeCharacters = 5;
  mask: string;

  @Input()
  get countryCode(): CountryCode {
    return this._countryCode;
  }
  set countryCode(countryCode: CountryCode) {
    this._countryCode = countryCode;
    if (this.mask !== this.getMask(countryCode)) {
      this.setMask();
    }
  }
  private _countryCode: CountryCode;

  @Input() checkPostalCodeInDb = false;
  @Output() serviceCenterFound = new EventEmitter<ServiceCenter | null>(null);

  constructor(
    private shippingtoolsService: ShippingtoolsService,
    translate: TranslateService,
    cd: ChangeDetectorRef,
    @Optional() @Self() parentControl?: NgControl,
    @Optional() formGroupTemplateService?: FormGroupTemplateService
  ) {
    super(translate, cd, parentControl, formGroupTemplateService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setMask();

    this.postalCodeControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => {
          this.setMask();
          if (this.mask && this.postalCodeControl.value.length > this.mask.length) {
            this.postalCodeControl.setValue(this.postalCodeControl.value.slice(0, this.mask.length));
          }
        }),
        filter((value) => {
          return (
            this.checkPostalCodeInDb &&
            value?.length >= this.minZipCodeCharacters &&
            this.countryCode !== CountryCode.MEXICO &&
            !this.isCanadaZip(value)
          );
        }),
        switchMap((postalCode) => this.searchPostalCode(postalCode).pipe(take(1)))
      )
      .subscribe();

    this.setCustomErrorsPostalCode();
  }

  get postalCodeControl(): FormControl {
    return <FormControl>(this.parentControl?.control || this.control);
  }

  removeMask(): void {
    this.mask = '';
    this.cd.markForCheck();
  }

  getValidatorFunctions(): ValidatorFn[] {
    return [Validators.minLength(this.minZipCodeCharacters)];
  }

  private setMask(): void {
    this.mask = this.getMask(this.countryCode);
    this.postalCodeControl.updateValueAndValidity();
    this.cd.markForCheck();
  }

  private getMask(countryCode: CountryCode): string {
    const postalCode: string | null | undefined = this.postalCodeControl.value;

    let mask = '';
    if (postalCode) {
      switch (true) {
        case countryCode === CountryCode.UNITED_STATES:
          mask = Masks.zip.US;
          break;
        case countryCode === CountryCode.MEXICO:
          mask = '';
          break;
        case countryCode === CountryCode.CANADA || this.isCanadaZip(postalCode):
          mask = this.canadianMask(postalCode);
          break;
        default:
          mask = Masks.zip.US;
          break;
      }
    }

    return mask;
  }

  private isCanadaZip(value: string): boolean {
    return this.countryCode === CountryCode.CANADA || (RegExp(/^[a-z]/i).test(value) && !!this.countryCode === false);
  }

  private canadianMask(postalCode: string): string {
    const letterFourth: string = postalCode.charAt(3);
    if (letterFourth) {
      switch (letterFourth) {
        case '-':
          return Masks.zip.CAAux;
        case ' ':
          return Masks.zip.CA2;
        default:
          return Masks.zip.CA;
      }
    }

    return Masks.zip.CA;
  }

  private setCustomErrorsPostalCode(): void {
    this.customError = this.customError ?? [];
    if (!Array.isArray(this.customError)) {
      this.customError = [this.customError];
    }
    this.customError.push({
      value: 'notInDatabase',
      label: this.translate.instant('form.errors.notInDatabase'),
    });
  }

  private searchPostalCode(postalCd): Observable<ServiceCenter | null> {
    if (!postalCd || postalCd.length < this.minZipCodeCharacters) {
      return of(null);
    }
    return this.shippingtoolsService.getServiceCenterInfo({ postalCd }).pipe(
      catchError((error: HttpErrorResponse) => {
        // if its a 404 error, set a validation error on the field, else gulp up the error
        // since the api may be down
        const moreInfoError: ErrorDataMoreInfoResponse[] | null = error?.error?.error?.moreInfo;
        const innerMessageError = moreInfoError?.find((err) => err.location === 'message')?.message;
        const is404Error = innerMessageError?.startsWith('404');

        if (error.status === 404 || is404Error) {
          this.postalCodeControl.setErrors({ notInDatabase: true });
          this.postalCodeControl.markAsTouched();
          this.cd.markForCheck();
        }

        return of(null);
      }),
      tap((serviceCenter) => this.serviceCenterFound.emit(serviceCenter))
    );
  }
}
