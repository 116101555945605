import { AbstractControl, ValidationErrors } from '@angular/forms';
import { BUSINESS_CONSTRAINTS, StringFormatHelper } from '@ltlc/core';

export class ProNumberHelper {
  static readonly maxProNumbers = BUSINESS_CONSTRAINTS.MAX_PRO_NUMBERS;
  static readonly allowedSeparationBaseRegex: RegExp = /\s*[,\n\t;]+\s*/;
  static readonly allowedSeparationWithSpaceRegex: RegExp = /\s*[ ,\n\t;]+\s*/;

  static isValidProNumber(
    proSearchInput: string,
    allowSpaceSeparation: boolean,
    maxProNumbers = this.maxProNumbers
  ): boolean {
    if (!proSearchInput?.trim()) {
      return false;
    }
    if (allowSpaceSeparation) {
      proSearchInput = proSearchInput.replace(/[ \t]+/g, ','); // Replace all spaces with commas
    }

    // Create array of PRO(s) and format it
    let proNumbers = [proSearchInput];
    if (this.isMoreThanOnePro(proSearchInput)) {
      proNumbers = proSearchInput.split(this.allowedSeparationBaseRegex);
    } else {
      proSearchInput = proSearchInput.replace('-', '');
      if (!this.isCorrectProNumberFormat(proSearchInput)) {
        return false;
      }
    }

    proNumbers = proNumbers.map(ProNumberHelper.formatProNumber);

    if (proNumbers.length > maxProNumbers) {
      return false;
    }

    return proNumbers
      .map((p) => p.replace('-', ''))
      .filter((v) => !!v)
      .every((p) => this.isCorrectProNumberFormat(p));
  }

  static convertProToDatabaseFormat(proNumber: string): string {
    // Converting any 10 or 11 formatted pro number string to a proper 9-digit format to accomodate the database
    proNumber = (proNumber ?? '').trim();

    while (proNumber.charAt(0) === '0') {
      //Remove prefix zeros
      proNumber = proNumber.substring(1);
    }
    if (
      [' ', '-', '0'].includes(proNumber.charAt(3)) &&
      proNumber.length > BUSINESS_CONSTRAINTS.LENGTH_PRO_NUMBER_MIN
    ) {
      // xxx0xxxxxx
      // xxx xxxxxx
      // xxx-xxxxxx
      // to xxxxxxxxx
      proNumber = StringFormatHelper.setCharAt(proNumber, 3, '');
    }
    return proNumber;
  }

  static formatProNumber(proNumber: string): string {
    const flatFormat = ProNumberHelper.convertProToDatabaseFormat(proNumber); // 9-digit format: xxxxxxxxx
    const end = flatFormat.slice(3);

    // proNbr format is XXX-YYYYYY
    return !!proNumber ? `${flatFormat.slice(0, 3)}-${end.substring(end.length - 6)}` : '';
  }

  /**
   * Formats any proNumber format to an 11 digit proNumber 0xxx0xxxxxx. Used needed for legacy apis
   *
   * @param proNumber
   * @returns
   */
  static formatTo11DigitPro(proNumber: string): string {
    const normalizedProNumber = ProNumberHelper.convertProToDatabaseFormat(proNumber);

    return `0${normalizedProNumber.slice(0, 3)}0${normalizedProNumber.slice(3, 9)}`;
  }

  private static isCorrectProNumberFormat(input: string): boolean {
    return RegExp(/^\d+$/).test(input) && input.length === BUSINESS_CONSTRAINTS.LENGTH_PRO_NUMBER_MIN;
  }

  private static isMoreThanOnePro(proNum: string): boolean {
    return RegExp(this.allowedSeparationBaseRegex).test(proNum);
  }
}

export const isValidProNumberValidator = (control: AbstractControl): ValidationErrors => {
  const proNumber = control.value?.trim();

  if (!!proNumber) {
    const allowSpaceSeparation = proNumber.includes(' ');
    const isValid = ProNumberHelper.isValidProNumber(proNumber, allowSpaceSeparation, ProNumberHelper.maxProNumbers);
    if (isValid) {
      return null;
    }
  }

  return { invalidProNumber: true };
};
