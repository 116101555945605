import { Injectable } from '@angular/core';
import {
  AccountFinancialInfoResponseData,
  AddressBook,
  AddressBookApiResponse,
  AddressBookData,
  CountryCode,
  FlatAccountData,
  InquirerInfo as ShipperCustomerProfileAddress,
  MembershipService,
  PickupRequestApiService,
  PreferredAccount,
  UserAccount,
  UserMaintenanceService,
} from '@ltlc/api';
import { ArraySortHelper, ErrorHandlerService, ZipMaskHelper } from '@ltlc/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { LtlConnectUserService } from '../../../services/user.service';
import { defaultDockingInterval } from '../../form-controls';
import { TreeListOption } from '../enums/account-list-light.enum';
import { AccountListHelper } from './../helpers/account-list-light.helper';
import { AccountListLightTreeNode } from './../interfaces/account-list-light.interface';
import { StorageAccountsService } from './accounts-storage.service';

/**
 * TODO: See if we can refactor the reload logic.
 * Maybe lazy load the api's inside the tree component and handle errors in that component.
 * Othwerwise maybe gather all failed api's and resolve them all on reload button.
 * Ideally the BE would provide the lists from a single api call, which would simplify the entire logic.
 */
interface EntityState<T> {
  data?: T;
  loaded?: boolean;
  error?: any;
}
@Injectable({
  providedIn: 'root',
})
export class AccountDataSourcesService {
  constructor(
    private translate: TranslateService,
    private membershipService: MembershipService,
    private userService: LtlConnectUserService,
    private pickupRequestApiService: PickupRequestApiService,
    private userMaintenanceService: UserMaintenanceService,
    private errorHandlerService: ErrorHandlerService,
    private storageAccountsService: StorageAccountsService
  ) {}

  newAddress(): Observable<AccountListLightTreeNode> {
    return this.userDefaultAddress().pipe(
      map((userInfo) => {
        const newAddress = <AccountListLightTreeNode>{
          name: this.translate.instant('accountListLight.newAddress'),
          listId: TreeListOption.newAddress.toString(),
          matIconName: 'add_location',
          type: this.translate.instant(`locationType.A`),
          sticky: true,
          addressForm: {
            listId: TreeListOption.newAddress.toString(),
            countryCode: CountryCode.UNITED_STATES,
            dockingInterval: defaultDockingInterval,
          },
        };

        if (userInfo) {
          newAddress.addressForm = {
            ...newAddress.addressForm,
            contactEmail: userInfo.addressForm.contactEmail,
            contactPhoneNumber: userInfo.addressForm.contactPhoneNumber,
            contactFullName: userInfo.addressForm.contactFullName,
          };
        }

        return newAddress;
      })
    );
  }

  // TODO: Consider recalling the api instead for data integrity
  saveAsNewAddress(addressBook: AddressBookData): Observable<AddressBook> {
    return this.userMaintenanceService.addNewAddressBook(addressBook).pipe(
      tap((savedAddress: AddressBook) => {
        const addresses = this._addressBookData$.getValue().data;
        addresses.unshift(savedAddress);
        this._addressBookData$.next({ data: addresses });
      })
    );
  }

  // TODO: Consider recalling the api instead for data integrity
  updateAddress(addressBook: AddressBookData, sequenceNbr: number): Observable<AddressBookApiResponse> {
    return this.userMaintenanceService.saveAddressBook(addressBook, sequenceNbr).pipe(
      tap((savedAddress: AddressBookApiResponse) => {
        const addresses = this._addressBookData$.getValue().data;
        const index = addresses.findIndex(
          (address: AddressBook) => address.sequenceNbr.toString() === sequenceNbr.toString()
        );
        if (index !== -1) {
          addresses[index] = savedAddress.data.addressBookEntry;
          this._addressBookData$.next({ data: addresses });
        }
      })
    );
  }

  private readonly _shipperAccounts$ = new BehaviorSubject<EntityState<ShipperCustomerProfileAddress[]> | null>(null);
  shipperAccounts(): Observable<ShipperCustomerProfileAddress[]> {
    const shipperAccounts$ = this.userService.getProfileInstId$().pipe(
      switchMap((profileInstId: string) => {
        return this.pickupRequestApiService.getShipperCustomerProfiles(profileInstId).pipe(
          retry(1),
          this.errorHandlerService.returnValueOnStatus([404, 400], []),
          map((shipperAccounts: ShipperCustomerProfileAddress[]) => {
            return ArraySortHelper.orderBy(shipperAccounts, ['contactInfo.companyName'], ['asc']);
          })
        );
      })
    );

    return this.resolveDataSourceData(shipperAccounts$, this._shipperAccounts$, []);
  }

  private readonly flatAccountsMap = new Map<string, BehaviorSubject<FlatAccountData>>();
  flatAccounts(accountIds: string[]): Observable<FlatAccountData[]> {
    if (!accountIds || !accountIds.length) {
      return of([]);
    }
    accountIds = accountIds.sort();
    const newAccountIds = accountIds.filter((accountId) => !this.flatAccountsMap.has(accountId));

    return this.userMaintenanceService.getFlatAccountList(newAccountIds, true).pipe(
      retry(2),
      this.errorHandlerService.returnValueOn404([]),
      map((flatAccounts) => {
        flatAccounts.forEach((flatAccount: FlatAccountData) => {
          if (!flatAccount.countryCode?.trim()) {
            if (
              ZipMaskHelper.isValidPostalCode(flatAccount.postalCode, CountryCode.UNITED_STATES) &&
              ZipMaskHelper.isUSState(flatAccount.stateCode)
            ) {
              flatAccount.countryCode = CountryCode.UNITED_STATES;
            } else if (ZipMaskHelper.isValidPostalCode(flatAccount.postalCode, CountryCode.CANADA)) {
              flatAccount.countryCode = CountryCode.CANADA;
            }
          }
        });

        return ArraySortHelper.orderBy(flatAccounts, ['name'], ['asc']);
      })
    );
  }

  private _reloadNestedShipperAccounts$ = new BehaviorSubject(null);
  nestedShipperAccounts(): Observable<AccountListLightTreeNode[] | null> {
    return this._reloadNestedShipperAccounts$.pipe(
      switchMap(() => {
        return this.shipperAccounts().pipe(
          switchMap((shippperAccounts: ShipperCustomerProfileAddress[]) => {
            if (this._shipperAccounts$.getValue()?.error) {
              return of(this.reloadNode());
            }

            return combineLatest([this.userDefaultAddress()]).pipe(
              map(([userInfo]) => {
                const mappedShipperAccounts = shippperAccounts.map((acc) =>
                  AccountListHelper.mapShipperAccount(acc, this.translate)
                );

                return AccountListHelper.populateWthDefaultUserContactInfo(mappedShipperAccounts, userInfo);
              })
            );
          })
        );
      })
    );
  }

  private readonly _preferredAccounts$ = new BehaviorSubject<EntityState<PreferredAccount[]> | null>(null);
  preferredAccounts(): Observable<PreferredAccount[]> {
    const accounts$ = this.userService.getProfileInstId$().pipe(
      switchMap((profileInstId: string) => {
        return this.membershipService.preferredAccounts(profileInstId).pipe(this.errorHandlerService.returnValue([]));
      })
    );

    return this.resolveDataSourceData(accounts$, this._preferredAccounts$, []);
  }

  private _reloadNestedAssociatedAccounts$ = new BehaviorSubject(null);
  nestedAssociatedAccounts(forcePM = false): Observable<AccountListLightTreeNode[] | null> {
    if (this.userService.isPartnerManagerUser || forcePM) {
      return combineLatest([this.partnerMasterUserAdmin(), this.userDefaultAddress()]).pipe(
        map(([partnerMasterUserAdmin, userInfo]) => {
          return AccountListHelper.populateWthDefaultUserContactInfo(partnerMasterUserAdmin, userInfo);
        })
      );
    }
    return this._reloadNestedAssociatedAccounts$.pipe(
      switchMap(() => {
        return this.userService.getProfileInstId$().pipe(
          switchMap((profileInstId) => {
            if (!profileInstId) {
              return of(null);
            }
            return combineLatest([this.userDefaultAddress(), this.associatedAccounts(), this.preferredAccounts()]).pipe(
              switchMap(([userInfo, associatedAccounts, preferredAccounts]) => {
                if (this._associatedAccounts$.getValue().error) {
                  return of(this.reloadNode());
                }
                let flatAccountApiFail = false;
                const accountIds = associatedAccounts.map((account: UserAccount) => account.acctInstId.toString());
                return this.flatAccounts(accountIds).pipe(
                  catchError((error) => {
                    flatAccountApiFail = true;
                    return of([]);
                  }),
                  map((flatAccounts: FlatAccountData[]) => {
                    const missingFlatAccounts = [];
                    for (const accountId of accountIds) {
                      if (!flatAccounts.find((flatAccount) => flatAccount.accountId.toString() === accountId)) {
                        missingFlatAccounts.push(accountId);
                      }
                    }

                    // Map accounts in corresponding levels
                    let mappedFlatAccounts = AccountListHelper.mapNestedFlatAccounts(
                      flatAccounts,
                      this.translate,
                      this.storageAccountsService,
                      preferredAccounts
                    );

                    const flatAccountIds: string[] = flatAccounts.map((acc) => acc.accountId.toString());
                    for (const account of associatedAccounts) {
                      // Add missing shipper accounts not returned from flat account api
                      if (!flatAccountIds.includes(account.acctInstId.toString())) {
                        let mappedAssociated = AccountListHelper.mapAssociatedAccount(account, this.translate);
                        mappedAssociated = AccountListHelper.checkPreferredAccount(
                          mappedAssociated,
                          account.acctInstId.toString(),
                          preferredAccounts
                        );
                        mappedFlatAccounts.push(mappedAssociated);
                      }
                    }

                    const populated = AccountListHelper.populateWthDefaultUserContactInfo(mappedFlatAccounts, userInfo);
                    const ordered = AccountListHelper.orderByFavoriteAndName(populated);

                    if (flatAccountApiFail) {
                      // reload flat accounts option if api failed
                      ordered.unshift(...this.reloadNode());
                    }

                    return ordered;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  userDefaultAddress(): Observable<AccountListLightTreeNode | null> {
    return this.userService.webUser$.pipe(
      map((webUser) => {
        if (!webUser) {
          return null;
        }
        return AccountListHelper.mapUserGeneralInfo(webUser, this.translate);
      })
    );
  }

  private readonly financialInfoMap = new Map<
    string,
    BehaviorSubject<EntityState<AccountFinancialInfoResponseData> | null>
  >();
  financialInfo(idAccount: string): Observable<AccountFinancialInfoResponseData | null> {
    if (!this.financialInfoMap.has(idAccount)) {
      this.financialInfoMap.set(
        idAccount,
        new BehaviorSubject<EntityState<AccountFinancialInfoResponseData> | null>(null)
      );
    }
    const financialInfo$ = this.membershipService.financialInfoAccounts(idAccount);

    return this.resolveDataSourceData(financialInfo$, this.financialInfoMap.get(idAccount), null);
  }

  private readonly _addressBookData$ = new BehaviorSubject<EntityState<AddressBook[]> | null>(null);
  addressBook(): Observable<AddressBook[]> {
    return this.resolveDataSourceData(
      this.userMaintenanceService
        .getAddressBookEntries()
        .pipe(this.errorHandlerService.returnValueOnStatus([400, 404], [])),
      this._addressBookData$,
      []
    ).pipe(
      map((addresses: AddressBook[]) => {
        return ArraySortHelper.orderBy(addresses, ['companyName'], ['asc']);
      })
    );
  }

  private _reloadAddressBookWithUserInfo$ = new BehaviorSubject(null);
  addressBookWithUserInfo(): Observable<AccountListLightTreeNode[] | null> {
    return this._reloadAddressBookWithUserInfo$.pipe(
      switchMap(() => {
        return combineLatest([this.addressBook(), this.userDefaultAddress()]).pipe(
          map(([addressBook, userInfo]) => {
            if (this._addressBookData$.getValue()?.error || !userInfo) {
              return this.reloadNode();
            }
            if (Array.isArray(addressBook)) {
              let mapped = addressBook.map((addressBook: AddressBook) =>
                AccountListHelper.mapAddressBook(addressBook, this.translate)
              );
              return AccountListHelper.populateWthDefaultUserContactInfo(mapped, userInfo);
            }

            return null;
          })
        );
      })
    );
  }

  clearDataSourcesCache(): void {
    this._addressBookData$.next(null);
    this.financialInfoMap.clear();
    this._associatedAccounts$.next(null);
    this._preferredAccounts$.next(null);
    this.flatAccountsMap.clear();
    this._shipperAccounts$.next(null);
  }

  private readonly _associatedAccounts$ = new BehaviorSubject<EntityState<UserAccount[]> | null>(null);
  private associatedAccounts(): Observable<UserAccount[]> {
    const accounts$ = this.userService.getProfileInstId$().pipe(
      switchMap((profileInstId) => {
        return this.membershipService.associatedAccounts(profileInstId).pipe(
          this.errorHandlerService.returnValueOnStatus([404, 400], []), // These error codes mean that the user does not have any associated accounts
          map((accounts: UserAccount[]) => {
            return ArraySortHelper.orderBy(accounts, ['acctName'], ['asc']);
          })
        );
      })
    );

    return this.resolveDataSourceData(accounts$, this._associatedAccounts$, []);
  }

  private partnerMasterUserAdmin(): Observable<AccountListLightTreeNode[]> {
    return this.userService.getPartnerByUser$.pipe(
      map((locationUser) => {
        return AccountListHelper.mapPartnerLocationsToAccountListNode(locationUser, this.translate);
      })
    );
  }

  private readonly _combinedNestedAssociatedAndShipperAccounts$ = new BehaviorSubject<
    AccountListLightTreeNode[] | null
  >(null);
  combinedNestedAssociatedAndShipperAccounts(): Observable<AccountListLightTreeNode[] | null> {
    return this._combinedNestedAssociatedAndShipperAccounts$.pipe(
      switchMap((cachedCombined) => {
        if (cachedCombined) {
          return of(cachedCombined);
        }
        return combineLatest([this.nestedAssociatedAccounts(), this.nestedShipperAccounts()]).pipe(
          map(([associatedAccounts, shipperAccounts]) => {
            if (
              [associatedAccounts, shipperAccounts].some((accArr) =>
                (accArr ?? []).some((acc) => acc.listId === TreeListOption.reload)
              )
            ) {
              return (associatedAccounts ?? []).concat(shipperAccounts);
            }
            const initDatasource = AccountListHelper.MatTreeFlatDataSourceAccountList();
            const treeFlattener = initDatasource.treeFlattener;
            const associatedAccountsFlattened = treeFlattener.flattenNodes(associatedAccounts);
            const associatedAccountsIds = associatedAccountsFlattened.map((account) => account.data.rawData.id);
            shipperAccounts = shipperAccounts.filter((shipperAccount) => {
              return !associatedAccountsIds.includes(shipperAccount.rawData.id) || shipperAccount.rawData.reload;
            });
            return associatedAccounts.concat(shipperAccounts);
          }),
          tap((combined) => {
            this._combinedNestedAssociatedAndShipperAccounts$.next(combined);
          })
        );
      })
    );
  }

  // TODO: See if we can do sth to improve this logic
  private reloadNode(): AccountListLightTreeNode[] {
    return [
      {
        name: this.translate.instant('accountListLight.dataError'),
        address: this.translate.instant('accountListLight.errorOccurred'),
        matIconName: 'warning',
        listId: TreeListOption.reload,
        rawData: {
          reload: () => window.location.reload(),
        },
      },
    ];
  }

  private resolveDataSourceData(
    api: Observable<any>,
    storeSubject: BehaviorSubject<EntityState<any> | null>,
    fallbackValue: any
  ): Observable<any> {
    const killObserver$ = new Subject<void>();
    const resolve$ = storeSubject.pipe(
      switchMap((entity: EntityState<any> | null) => {
        if (entity?.loaded === false) {
          return storeSubject.pipe(
            filter((entity: EntityState<any> | null) => entity?.loaded === true),
            take(1),
            map((entity: EntityState<any> | null) => entity?.data)
          );
        }
        if (!!entity?.data || !!entity?.error) {
          return of(entity.data ?? fallbackValue);
        }
        if (!entity) {
          storeSubject.next({ loaded: false });
        }

        const callApi$ = api.pipe(
          retry(2),
          catchError((error) => {
            console.error(error);
            return of({ error });
          }),
          switchMap((data) => {
            if (data.error) {
              storeSubject.next({ loaded: true, data: fallbackValue, error: data.error });
            } else {
              storeSubject.next({ loaded: true, data });
            }
            return storeSubject.pipe(map((entity: EntityState<any> | null) => entity?.data));
          })
        );

        return callApi$;
      })
    );

    /**
     * We do this subscription to ensure the termination of the api calls.
     */
    return new Observable((observer) => {
      resolve$.pipe(takeUntil(killObserver$)).subscribe((data) => {
        const stored = storeSubject.getValue();
        if (stored?.error) {
          observer.next(fallbackValue);
        } else {
          observer.next(data);
        }
        if (stored?.loaded) {
          killObserver$.next();
          killObserver$.complete();
          observer.complete();
        }
      });
    });
  }
}
