import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import { CountryStateOption } from './interfaces';
import {
  AccessorialResponse,
  AccessorialsShipmentInfoResponse,
  CreateSpotQuotePayload,
  CreateSpotQuoteResponse,
  CreateSpotQuoteResponseData,
  MexicanCitiesResponse,
  RateQuotePdfApiResponse,
  RateQuotePDFDocument,
  RatingBody,
  RatingResponse,
  RatingResponseData,
  RQDetailPDFRequest,
} from './interfaces/rating.interface';
import { SfrApiResponse } from './interfaces/sfr.interface';

@Injectable({ providedIn: 'root' })
export class RatingApiService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get ratingURI(): string {
    return `${this.apiUri}/ratequote/1.0/`;
  }

  createRateQuote(rateQuote: RatingBody): Observable<RatingResponseData> {
    const url = `${this.ratingURI}ratequotes`;
    return this.httpClient.post<RatingResponse>(url, rateQuote).pipe(map((response: RatingResponse) => response?.data));
  }

  getRQDetailPDF(printRequest: RQDetailPDFRequest): Observable<RateQuotePDFDocument> {
    const url = `${this.ratingURI}ratequotes/detail/pdf`;
    return this.httpClient
      .post<RateQuotePdfApiResponse>(url, printRequest)
      .pipe(map((response: RateQuotePdfApiResponse) => response.data.document));
  }

  getRateQuotes(confirmationNumber: string): Observable<RatingResponseData> {
    const url = new URL(`${this.ratingURI}ratequotes/${confirmationNumber}`);
    url.searchParams.append('showSpecialServiceCharges', 'true');

    return this.httpClient.get<RatingResponse>(url.toString()).pipe(
      map((response: RatingResponse) => {
        response.data.rateQuote.shipmentInfo.commodity = response.data.rateQuote.shipmentInfo.commodity.map((c) => {
          c.nmfcClass = c.nmfcClass?.trim();
          return c;
        });
        return response.data;
      })
    );
  }

  getSpotQuoteDetail(spotQuoteNumber: string): Observable<CreateSpotQuoteResponseData> {
    const url = `${this.ratingURI}spotquotes/${spotQuoteNumber}?showSpecialServiceCharges=true`;
    return this.httpClient.get<CreateSpotQuoteResponse>(url).pipe(map((response) => response.data));
  }

  getSfrStatus(accountId: number | string): Observable<boolean> {
    return this.httpClient.get(`${this.ratingURI}accounts/${accountId}/sfr-status`).pipe(
      map((accountInfo: SfrApiResponse) => {
        return accountInfo.data.sfrPricingStatus;
      })
    );
  }

  createSpotQuote(spotQuoteBody: CreateSpotQuotePayload): Observable<CreateSpotQuoteResponseData> {
    const url = `${this.ratingURI}web/spotquotes`;
    return this.httpClient.post<CreateSpotQuoteResponse>(url, spotQuoteBody).pipe(map((response) => response.data));
  }

  getPricingAccessorials(madCode: string): Observable<AccessorialsShipmentInfoResponse[]> {
    const url = `${this.ratingURI}ratequotes/pricing-accesorials/${madCode}`;
    return this.httpClient.get<AccessorialResponse>(url).pipe(map((response) => response.data));
  }

  getMexicanCities(): Observable<CountryStateOption[]> {
    const url = `${this.ratingURI}ratequotes/mexicanCities`;
    return this.httpClient.get<MexicanCitiesResponse>(url).pipe(map((response) => response.data));
  }
}
