import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable, isDevMode } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

@Injectable({ providedIn: 'root' })
export class WebConfigService {
  constructor(private configManager: ConfigManagerService) {}

  get isProd(): boolean {
    return coerceBooleanProperty(this.getSetting('isProd'));
  }

  get appVersion(): string {
    return this.getSetting('appVersion');
  }

  // This is needed due to the xpoc libraries using apiUrl as a key instead of apiUri which is semantically correct.
  get apiUrl(): string {
    return this.apiUri;
  }

  get apiUri(): string {
    return this.getSetting('apiUri');
  }

  get connectApiUri(): string {
    return this.getSetting('connectApiUri');
  }

  get dotNetApi(): string {
    return this.getSetting('dotNetApi');
  }

  get captchaKey(): string {
    return !!window['Cypress'] && isDevMode() ? this.getSetting('captchaKeyCypress') : this.getSetting('captchaKey');

    // return this.getSetting('captchaKeyCypress'); // For testing locally
  }

  getSetting<T = string>(key: string) {
    return <T>this.configManager.get<T>(key);
  }
}
