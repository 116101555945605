import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LtlConnectUserService } from '@ltlc/ltl-core';
import { Observable, Observer, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AnalyticsData } from './consts/analytics.const';
import { XpoTrackingAnalyticsService } from './tracking-analytics-base.service';

@Injectable({ providedIn: 'root' })
export class XpoGoogleTagManagerAnalyticsService extends XpoTrackingAnalyticsService {
  private get dataLayer(): any {
    return window['dataLayer'];
  }
  constructor(
    @Inject(DOCUMENT) document: Document,
    private router: Router,
    private userService: LtlConnectUserService
  ) {
    super(document);
  }

  trackEvent(eventName: string, username: string, eventValue?: Object): void {
    this.dataLayer.push({
      event: eventName,
      userId: username,
      ...(eventValue ?? {}),
    });
  }
  trackPageView(pagePath: string, user: AnalyticsData): void {
    this.dataLayer.push({
      event: 'LTL Connect Page View',
      pagePath: pagePath,
      userId: user?.userId,
      userEmail: user?.emailAddress,
    });
  }

  protected insertScript(): Observable<boolean> {
    return new Observable<boolean>((observer: Observer<boolean>) => {
      const s = this.document.createElement('script');
      s.type = 'text/javascript';
      s.src = `https://www.googletagmanager.com/gtm.js?id=${this.trackingId()}`;
      s.text = '';
      s.async = true;

      s.onload = () => {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        observer.next(true);
        observer.complete();
      };

      s.onerror = (error: any) => {
        observer.error('Couldnt load script' + s.src);
      };

      this.appendToHead(s);
    });
  }
  protected finalizeScriptInsertion(): void {
    const body = this.document.getElementsByTagName('body')[0];
    const noScript = this.document.createElement('noscript');
    const iFrame = this.document.createElement('iframe');

    iFrame.src = `https://www.googletagmanager.com/ns.html?id=${this.trackingId()}`;
    iFrame.height = '0';
    iFrame.width = '0';
    iFrame.style.display = 'none';
    iFrame.style.visibility = 'hidden';

    noScript.appendChild(iFrame);
    body.appendChild(noScript);
  }

  protected trackingId(): string {
    return environment.tagManagerTrackingId;
  }

  protected initialConfigurationSetup(): void {
    this.router.events
      .pipe(
        startWith(new NavigationEnd(null, this.router.url, this.router.url)),
        filter((event) => event instanceof NavigationEnd),
        map((v: NavigationEnd) => v.urlAfterRedirects),
        distinctUntilChanged(),
        debounceTime(1000),
        // TODO: remove ths when the user webUser$ has 3 states, pending, null, or user
        switchMap((url) => {
          return this.userService.isLoggedIn()
            ? this.userService.webUser$.pipe(map((user) => [url, user?.profile?.email]))
            : of([url, environment.userFakeEmail]);
        })
      )
      .subscribe(([url, email]) => {
        this.trackPageView(url, { userId: email, emailAddress: email });
      });
  }
}
