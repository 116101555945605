export const BUSINESS_CONSTRAINTS = {
  /**
   * @type number
   * @description Measured in days.
   */
  MAX_PICKUP_WINDOW: 30,
  /**
   * @type number
   * @description Maximum number of lines allowed.
   * */
  MAX_PICKUP_LINE_ITEMS: 50,

  /**
   * @type number
   * @description Maximum number of characters allowed for template name.
   * */
  MAX_LENGTH_TEMPLATE_NAME: 71,

  /**
   * @type number
   * @description Maximum number of characters allowed for extension numbers.
   * */
  MAX_LENGTH_PHONE_EXTENSION: 5,

  /**
   * @type number
   * @description Min freight class for commodities when the exhibition accessorial is selected.
   * */
  MIN_EXHIBITION_FREIGHT_CLASS: 125,

  /**
   * @type number
   * @description Minumum number value for Excesive Value Coverage..
   * */
  EVC_MIN_VALUE: 1,

  /**
   * @type number
   * @description Maximum number value for Excesive Value Coverage.
   * */
  EVC_MAX_VALUE: 999999,

  /**
   * @type number
   * @description Minimum number caracters for initials for Excesive Value Coverage.
   * */
  EVC_INITIALS_MIN_LENGTH: 2,

  /**
   * @type number
   * @description Maximum number caracters for initials for Excesive Value Coverage.
   * */
  EVC_INITIALS_MAX_LENGTH: 3,

  /**
   * @description Maximum number of commodities allowed.
   * */
  MAX_COMMODITIES_COUNT: 50,

  /**
   * @type string
   */
  DOCK_TIME_MAX: '18:0',

  /**
   * Time where RQs go to the next day if the users current hour is after value
   */
  RATE_QUOTE_CUTOFF_HOUR: 15,

  /**
   * @type number
   * @description Measured in days.
   */
  MAX_PALLETS_COUNT: 999,

  /**
   * @type number
   * @description Default number of shipment info rows
   */
  DEFAULT_SHIPMENT_ROWS_COUNT: 1,

  /**
   * @type number
   * @description Maximum weight for a shipment info row
   */
  MAX_WEIGHT: 99999,

  /**
   * @type number
   * @description Minimum number caracters for remarks in Hazmat General Info.
   * */
  HAZMAT_INFO_REMARKS_MAX_LENGTH: 450,

  /**
   * @type number
   * @description Minimum number caracters for contact name in Hazmat General Info.
   * */
  HAZMAT_INFO_CONTACTNAME_MAX_LENGTH: 60,

  /**
   * @type number
   * @description Maximum number caracters for code in Reference Number section.
   * */
  REFERENCE_NUMBER_CODE_MAX_LENGTH: 3,

  /**
   * @type number
   * @description Maximum number caracters for description in Reference Number section.
   * */
  REFERENCE_NUMBER_DESCRIPTION_MAX_LENGTH: 30,

  /**
   * @type number
   * @description Maximum number caracters for number in Reference Number section.
   * */
  REFERENCE_NUMBER_MAX_LENGTH: 30,

  /**
   * @type number
   * @description Number of characters.
   */
  MAX_COMMODITY_TITLE_LENGTH: 250,

  /**
   * @type number
   * @description Minimum weight for a commodity.
   */
  MIN_COMMODITY_WEIGHT: 1,

  /**
   * @type number
   * @description Maximum length for a commodity weight.
   */
  MAX_COMMODITY_WEIGHT_COUT: 99999,

  /**
   * @type number
   * @description Minimum units amount for a commodity.
   */
  MIN_COMMODITY_UNIT_COUNT: 1,

  /**
   * @type number
   * @description Maximum length for a commodity.
   */
  MAX_COMMODITY_UNIT_COUNT: 99999,

  /**
   * @type number
   * @description Maximum number of characters allowed for draft name.
   * */
  MAX_LENGTH_DRAFT_NAME: 60,

  /**
   * @type number
   * @description Maximum number of reference numbers allowed.
   * */
  MAX_REFERENCE_NUMBER_COUNT: 10,

  /**
   * @type number
   * @description Default weight for sfr commodity
   * */
  DEFAULT_WEIGHT_SFR_COMMODITY: 1000,

  /**
   * @type number
   * @description Default class for sfr commodity
   * */
  DEFAULT_CLASS_SFR_COMMODITY: '100',
  /**
   * @type string
   * @description Default ready time for template.
   * */
  DEFAULT_READY_TIME_TEMPLATE: '22:45',

  /**
   * @type number
   * @description Default ready time for template.
   * */
  DEFAULT_DOCK_CLOSE_TIME_TEMPLATE: '23:0',
  /**
   * @type string
   * @description End of the day at the service center
   */
  END_DAY_SERVICE_CENTER: '18:00',

  /**
   * @type number
   * @description Maximum number of rows allowed.
   * */
  MAX_ROWS_COUNT: 10,

  /**
   * @type number
   * @description Default number of rows
   */
  DEFAULT_ROWS_COUNT: 3,

  /**
   * @type number
   * @description Maximum days of quote history
   */
  MAX_DAYS_HISTORY_QUOTES: 90,

  /**
   * @type number
   * @description Maximum days of pickup request history
   */
  MAX_DAYS_HISTORY_PUR: 30,

  /**
   * @type number
   * @description Maximum valid business days of spot quote
   */
  MAX_BUSINESS_DAYS_SPOT_QUOTE_EXPIRATION: 2,

  /**
   * @type number
   * @description Maximum number of pro numbers allowed
   */
  MAX_PRO_NUMBERS: 35,
  MAX_PRO_NUMBERS_DOCS: 10,
  LENGTH_PRO_NUMBER_MIN: 9,

  MAX_ACCESSORIALS: 10,

  MIN_REMIT_AMOUNT: 1,

  MAX_REMIT_AMOUNT: 9999999999,

  MAX_DECLARED_VALUE_TOTAL_USD: 9999999,

  MAX_LENGTH_TRIM_PUR: 30,
  MAX_LENGTH_CITY: 20,
  MAX_NUM_LABELS: 100,
  MAX_TRANSIT_DAYS: 99,
  MAX_COMMIDITY_WEIGHT_LBS: 4500,
};
