import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URI, DOTNET_API_URI } from '../tokens';
import {
  ApplicationEnum,
  BaseActionParameter,
  BaseWebGridDetailRequestSnapshot,
  BaseWebGridDetailSnapshotResponse,
  FreightBillCopyPdfData,
  FreightBillCopyPdfRequest,
  LoadFormRequest,
  LoadFormResponse,
  WebActionRequest,
  WebActionResponse,
  WebFormBody,
  WebFormSubmitFormRequest,
  WebFormSubmitFormResponse,
  WebGridDetailRequest,
  WebGridDetailResponse,
} from './interfaces';
import {
  BaseWebGridItemResponse,
  WebGridRefreshRequest,
  WebGridRequest,
  WebGridSearchResponse,
} from './interfaces/web-submission-grid.interface';

@Injectable({ providedIn: 'root' })
export class WebSubmissionApiService {
  constructor(
    @Inject(DOTNET_API_URI) private baseApiUri: string,
    @Inject(API_URI) private apiUri: string,
    private httpClient: HttpClient
  ) {}

  get webSubmissionUri(): string {
    // Using ltl api uri as there are some customers with firewall blockers
    return `${this.apiUri}/ltlweb/1.0/api/webSubmission/`;
  }

  submitForm<K extends WebFormSubmitFormRequest, T extends WebFormSubmitFormResponse>(request: K): Observable<T> {
    const url = `${this.webSubmissionUri}webform/submitForm`;
    return this.httpClient.post<T>(url, request);
  }

  getNewForm<T extends WebFormBody>(loadFormRequest: LoadFormRequest): Observable<LoadFormResponse<T>> {
    const url = `${this.webSubmissionUri}webform/loadForm`;
    return this.httpClient.post<LoadFormResponse<T>>(url, loadFormRequest);
  }

  getWebAction<
    K extends BaseActionParameter = BaseActionParameter,
    T extends BaseActionParameter = BaseActionParameter
  >(webActionRequest: WebActionRequest<K>): Observable<WebActionResponse<T>> {
    const url = `${this.webSubmissionUri}web/Action`;
    return this.httpClient.post<WebActionResponse<T>>(url, webActionRequest);
  }

  getGridSearch<T extends BaseWebGridItemResponse = BaseWebGridItemResponse>(
    request: WebGridRequest
  ): Observable<WebGridSearchResponse<T>> {
    const url = `${this.webSubmissionUri}web/GridSearch`;
    return this.httpClient.post<WebGridSearchResponse<T>>(url, request);
  }

  gridRefresh(request: WebGridRefreshRequest): Observable<null> {
    const url = `${this.webSubmissionUri}web/GridRefresh`;
    return this.httpClient.post<null>(url, request);
  }

  getGridDetail<
    K extends BaseWebGridDetailRequestSnapshot = BaseWebGridDetailRequestSnapshot,
    T extends BaseWebGridDetailSnapshotResponse = BaseWebGridDetailSnapshotResponse
  >(request: WebGridDetailRequest<K>): Observable<WebGridDetailResponse<T>> {
    const url = `${this.webSubmissionUri}web/GridDetail`;
    return this.httpClient.post<WebGridDetailResponse<T>>(url, request);
  }

  // Get webforms enums TypeCdEnum, CurrencyCdEnum, ConditionTypeCdEnum, ActionEntityTypes, etc.
  getApplicationEnums(): Observable<ApplicationEnum[]> {
    const url = `${this.baseApiUri}webforms/app/ApplicationEnums`;
    return this.httpClient.get<ApplicationEnum[]>(url);
  }

  getfreightBillPdf(request: FreightBillCopyPdfRequest): Observable<FreightBillCopyPdfData> {
    const url = `${this.webSubmissionUri}webbillcopy/WebBillCopy`;

    return this.httpClient.post<FreightBillCopyPdfData>(url, request);
  }
}
