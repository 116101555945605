import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URI } from '../tokens';
import {
  GetShipmentStatusResp,
  GetShipmentTrackingEventsResponseData,
  ShipmentDetailsApiResponse,
  ShipmentTrackingEventsResponse,
} from './interfaces/tracking.interface';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(@Inject(API_URI) private apiUri: string, private httpClient: HttpClient) {}

  get shipmentTrackingURI(): string {
    return `${this.apiUri}/tracking/1.0/`;
  }

  getShipmentEvents(proNumber: string, showDetailed?: boolean): Observable<GetShipmentTrackingEventsResponseData> {
    let url = `${this.shipmentTrackingURI}shipments/${proNumber}/tracking-events`;
    if (!showDetailed) {
      url += '?levelOfDetail=S';
    }
    return this.httpClient.get<ShipmentTrackingEventsResponse>(url).pipe(map((v) => v.data));
  }

  getShipmentStatusDetailTracking(proNumbers: string[]): Observable<GetShipmentStatusResp> {
    const params = proNumbers.map((proNumber: string) => `referenceNumbers=${proNumber}`).join('&');
    const url = `${this.shipmentTrackingURI}shipments/shipment-status-details?${params}`;
    return this.httpClient.get<ShipmentDetailsApiResponse>(url).pipe(
      map((statusDetails) => {
        return statusDetails.data;
      })
    );
  }
}
